import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import axios from 'axios'
import filterLocale from '../utils/filter-locale'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import qs from 'qs'
import Tool from '../svg/tools'
import { Clipboard, MapPin } from 'react-feather'

// for forms
import { 
	StyledForm,
	HomeButtonRow,
	HomeButton,
	FieldRow,
	FieldWrap,
	Required,
	StyledError, 
} from '../form/styles'
import { Formik, Field } from 'formik'
import assign from 'lodash.assign'
import { flat, validationSwitch, validationSchema } from '../form/utils'
import FormFieldGroup from '../form/FormFieldGroup'
import FormFieldSingle from '../form/FormFieldSingle'
import {
	SupportContactContainer,
	SupportContainer,
	SupportContainerHolder,
	ContactContainer,
	SupportTitle,
	SupportSubTitle,
	ItemsContainer,
	SupportItem,
	SvgContainer,
	ItemInfo,
	ItemTitle,
	ItemSub,
	ContactTitle,
	ContactDescription,
	CheckboxContainer
} from './styles'
import PopUp from '../modal/forms'
import { Lottie } from '@crello/react-lottie'
import { CustomCheckboxInput } from '@reach/checkbox'
import * as animationCheckbox from '../../animations/checkbox.json'
import localeCheck from '../../components/utils/locale-check'

const SupportContact = ({ locale }) => {
	const { support, contact } = useStaticQuery(graphql`
		query supportContactQuery {
			support: allContentfulFeaturedItems(
				filter: { contentful_id: { eq: "59UjEaKmVtFzduaw7Lt6yR" } }
			) {
				edges {
					node {
						title
						subtitle
						node_locale
						items {
							name
							body {
								json
							}
						}
					}
				}
			}
			contact: allContentfulForm(
				filter: { contentful_id: { eq: "6D1VD5gGdEys2GwRQKDpWf" } }
			) {
				edges {
					node {
						title
						description
						endpoint
						node_locale
						items {
							... on ContentfulFormField {
								helpText
								inputAutocomplete
								inputId
								inputName
								inputPlaceholder
								inputRequired
								inputType
								textareaCols
								textareaRows
								icon
								validationText
								width
								options
								node_locale
								label
							}
							... on ContentfulFormGroup {
								title
								items {
									width
									validationText
									textareaRows
									textareaCols
									options
									node_locale
									label
									inputType
									inputRequired
									inputPlaceholder
									inputName
									inputId
									inputAutocomplete
									icon
									helpText
								}
							}
						}
					}
				}
			}
		}
	`)
	const [{ node }] = filterLocale(support.edges, locale)
	const [{ node: nodeContact }] = filterLocale(contact.edges, locale)

	const {
		title: contactTitle,
		description: contactDescription,
		endpoint,
		items: contactItems,
	} = nodeContact

	const valuesMapped = flat(contactItems).map(item => ({
		[item.inputName]: '',
	}))
	valuesMapped.push({ readandagree: false })
	const validationMapped = flat(contactItems).map(item => ({
		[item.inputName]: validationSwitch(item.inputName, item.validationText),
	}))
	validationMapped.push({ readandagree: validationSwitch('readandagree') })
	/** New Object from mapped Array */
	const valuesObject = assign({}, ...valuesMapped)
	const validationObject = assign({}, ...validationMapped)

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <ItemSub>{children}</ItemSub>,
		},
	}

	const icons = [
		{
			icon: <Clipboard color="currentColor" width="45" height="45"></Clipboard>,
		},
		{
			icon: <Tool color="currentColor" width="45" height="45"></Tool>,
		},
		{
			icon: <MapPin color="currentColor" width="45" height="45"></MapPin>,
		},
	]
	const [showDialog, setShowDialog] = React.useState(false)
	// const [sendForm, { loading, error, data }] = useMutation(SEND_FORM)
	const isLocaleLink = locale !== 'en-AU' ? `/${locale}/` : '/'
	const links = [
		'support/registration/',
		locale === 'en-AU'
			? 'shop/?query=spare%2520parts'
			: 'support/contact/?subject=spare-parts',
		'support/repairs-and-parts/',
	]

	const defaultLocale = 'en-AU'
	const checkedLocale = localeCheck(locale, defaultLocale)
	return (
		<SupportContactContainer>
			<SupportContainer>
				<SupportContainerHolder>
					<SupportTitle>{node.title}</SupportTitle>
					<SupportSubTitle>{node.subtitle}</SupportSubTitle>

					<ItemsContainer>
						{node.items.map((item, key) => {
							return (
								<SupportItem key={key} to={isLocaleLink + links[key]}>
									<SvgContainer>{icons[key].icon}</SvgContainer>
									<ItemInfo>
										<ItemTitle>{item.name}</ItemTitle>
										{documentToReactComponents(item.body.json, options)}
									</ItemInfo>
								</SupportItem>
							)
						})}
					</ItemsContainer>
				</SupportContainerHolder>
			</SupportContainer>
			<ContactContainer>
				<ContactTitle>{contactTitle}</ContactTitle>
				<ContactDescription>{contactDescription}</ContactDescription>
				<Formik
					initialValues={valuesObject}
					validationSchema={validationSchema(validationObject)}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						setSubmitting(true)
						const url =
							'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8'
						const body = {
							origin: 'Contact Us WebSite',
							orgid: '00D58000000JkgU',
							retURL: 'https://maytronics.com.au/support/contact/?form=contact',
							'00N4H00000E2wq9': 'https://maytronics.com.au/support/contact',
							name: values.name,
							subject: values.subject,
							email: values.email,
							phone: values.phone,
							'00N5800000Du5Hs': values.serialNo,
							description: values.message,
							recordType: '0124H0000006NyI', // Contact Us
						}
						const salesForce =
							locale === 'en-AU' && (await axios.post(url, qs.stringify(body)))
						const createForm = await axios.post('/.netlify/functions/graphql', {
							query: `
								mutation createForm(
									$formName: String!
									$subject: String
									$name: String
									$email: String
									$phone: String
									$serialNo: String
									$message: String
									$locale: String
								) {
									createForm(
										formName: $formName
										subject: $subject
										name: $name
										email: $email
										phone: $phone
										serialNo: $serialNo
										message: $message
										locale: $locale
									) {
										_id
										formName
									}
								}
							`,
							variables: {
								formName: 'contact-us',
								locale,
								message: values.message.split('\n').join('<br />'),
								...values,
							},
						})
						if (createForm && createForm.status === 200) {
							resetForm()
							setSubmitting(false)
							return setShowDialog(true)
						}
						if (salesForce && salesForce.status === 200) {
							resetForm()
							setSubmitting(false)
							return setShowDialog(true)
						}
						alert(
							`An error has occured, please try again or call us on 1300 693 657\n\n${JSON.stringify(
								salesForce || createForm,
								undefined,
								2
							)}`
						)
						setSubmitting(false)
						return setShowDialog(true)
					}}
				>
					{({ isSubmitting, errors, touched, values }) => (
						<StyledForm>
							{contactItems.map((item, index) => {
								if (item.items) {
									const { title, items } = item
									return (
										<FormFieldGroup
											key={title + index}
											color="#252f3f"
											{...{ items, errors, touched, values }}
										/>
									)
								} else {
									return (
										<FormFieldSingle
											key={item.inputName + index}
											overrideWidth="full"
											color="#252f3f"
											{...{
												item: { ...item, helpText: null },
												errors,
												touched,
												values,
											}}
										/>
									)
								}
							})}
							<FieldRow>
								<FieldWrap>
									<Field name="readandagree" type="checkbox">
										{({ field, form: { setFieldValue } }) => (
											<div tw="flex w-full mb-4 items-center">
												<CheckboxContainer
													tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
													onClick={() =>
														setFieldValue(
															'readandagree',
															field.checked ? false : true
														)
													}
													onKeyDown={() =>
														setFieldValue(
															'readandagree',
															field.checked ? false : true
														)
													}
												>
													<CustomCheckboxInput
														{...field}
														checked={field.checked}
														name="readandagree"
														type="checkbox"
													/>
													<Lottie
														playingState={'playing'}
														speed={1}
														direction={field.checked ? 1 : -1}
														width={24}
														height={24}
														tw="bg-white rounded"
														className="lottie-container checkbox"
														config={{
															animationData: animationCheckbox.default,
															loop: false,
															autoplay: true,
															renderer: 'svg',
														}}
														aria-hidden
													/>
												</CheckboxContainer>
												<p tw="text-sm font-display">I have read and I agree to <a tw="font-bold underline hover:no-underline" href={`${checkedLocale}/terms/`} target="_blank">Maytronics Terms &#38; Conditions</a> and <a tw="font-bold underline hover:no-underline" href={`${checkedLocale}/privacy/`} target="_blank">Privacy Policy</a> <Required>*</Required></p>
											</div>
										)}
									</Field>
									{touched['readandagree'] && errors['readandagree'] ? (
										<StyledError>{`▲ ` + errors['readandagree']}</StyledError>
									) : (
										<></>
									)}
								</FieldWrap>
							</FieldRow>
							<FieldRow>
								<FieldWrap>
									<Field name="updates" type="checkbox">
										{({ field, form: { setFieldValue } }) => (
											<div tw="flex w-full mb-4 items-center">
												<CheckboxContainer
													tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
													onClick={() =>
														setFieldValue(
															'updates',
															field.checked ? false : true
														)
													}
													onKeyDown={() =>
														setFieldValue(
															'updates',
															field.checked ? false : true
														)
													}
												>
													<CustomCheckboxInput
														{...field}
														checked={field.checked}
														name="updates"
														type="checkbox"
													/>
													<Lottie
														playingState={'playing'}
														speed={1}
														direction={field.checked ? 1 : -1}
														width={24}
														height={24}
														tw="bg-white rounded"
														className="lottie-container checkbox"
														config={{
															animationData: animationCheckbox.default,
															loop: false,
															autoplay: true,
															renderer: 'svg',
														}}
														aria-hidden
													/>
												</CheckboxContainer>
												<p tw="text-sm font-display">I wish to receive updates and promotions from Maytronics.</p>
											</div>
										)}
									</Field>
								</FieldWrap>
							</FieldRow>
							<HomeButtonRow>
								<HomeButton type="submit" disabled={isSubmitting}>
									{isSubmitting ? 'Sending...' : 'Send Message'}
								</HomeButton>
							</HomeButtonRow>
						</StyledForm>
					)}
				</Formik>
				<PopUp showDialog={showDialog} close={() => setShowDialog(false)} />
			</ContactContainer>
		</SupportContactContainer>
	)
}

export default SupportContact
